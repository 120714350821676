import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { get, save } from '../redux/actions/transferenciaActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { getByNumero, setPending } from '../../redux/actions/chequeActions';
import { callStatus } from '../../utils/constants';
import { compareObject } from '../../utils/funtions';
import { getAll as bancos } from '../../redux/actions/bancoActions';

const { confirm, error, success } = Modal;

const ChequeEditor = ({
  dispatch,
  dataSource,
  stateData,
  visible,
  onCancel,
  onCreate,
  chequeTipo,
  banco,
  isCobro = true,
}) => {
  const formatDisplay = 'DD/MM/YYYY';
  const [form] = Form.useForm();

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));
      data['fecha_cobro'] = moment(moment(data.fecha_cobro));
      // data['cobro_numero'] = data.cobro.numero;
      // data['cobro_cliente'] = data.cobro.cliente.nombre;

      form.setFieldsValue(data);
      setInitial(data);
    }
    // else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
    //   handleShowMessage('El Cheque se guardó con éxito', 'success', onCancel);
    // }
    else if (stateData.object.status === callStatus.OBJECT_PENDING) {
      form.resetFields();
      setInitial({});
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  useEffect(() => {
    if (dataSource) {
      form.setFieldsValue(dataSource);
      setInitial(dataSource);
    }
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    bancos(dispatch);
    // eslint-disable-next-line
  }, []);

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const handleOnChangeTipo = (value) => {
    const name = chequeTipo.list.data.find((item) => item.id === value).nombre;

    form.setFieldsValue({
      tipo_nombre: name,
    });
  };

  const handleOnChangeBanco = (value) => {
    const name = banco.list.data.find((item) => item.id === value).nombre;

    form.setFieldsValue({
      banco_nombre: name,
    });
  };

  const handleOnSearch = (value) => {
    if (value) {
      dispatch(getByNumero(value));
    }
  };

  const numeroWatch = Form.useWatch('numero', form);

  useEffect(() => {
    console.log(numeroWatch);
    console.log(stateData.object);

    if (
      stateData.object.status === callStatus.OBJECT_SUCCESS &&
      form.getFieldValue('numero') === ''
    ) {
      dispatch(setPending());
    }

    // eslint-disable-next-line
  }, [numeroWatch]);
  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={stateData.object.data.id ? 'Editar Cheque' : 'Nuevo Cheque'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={stateData.object.data}
        >
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="id" label="id" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item name="key" label="key" style={{ display: 'none' }}>
                <Input />
              </Form.Item>
              <Form.Item
                name="tipo_nombre"
                label=""
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="banco_nombre"
                label=""
                style={{ display: 'none' }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="numero"
                label="Numero"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                {isCobro ? (
                  <Input />
                ) : (
                  <Input.Search
                    onSearch={handleOnSearch}
                    allowClear={true}
                    readOnly={stateData.object.data.id > 0}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item name="tipo_id" label="Tipo">
                <Select
                  onChange={handleOnChangeTipo}
                  disabled={stateData.object.data.id > 0}
                >
                  {chequeTipo.list.data.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="fecha"
                label="Fecha"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <DatePicker
                  format={formatDisplay}
                  style={{ width: '100%' }}
                  initialValue={moment(new Date())}
                  disabled={stateData.object.data.id > 0}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="fecha_cobro"
                label="Fecha Cobro"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <DatePicker
                  format={formatDisplay}
                  style={{ width: '100%' }}
                  initialValue={moment(new Date())}
                  disabled={stateData.object.data.id > 0}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="banco_id"
                label="Banco"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Select
                  onChange={handleOnChangeBanco}
                  disabled={stateData.object.data.id > 0}
                >
                  {banco.list.data.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.nombre}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="importe"
                label="Importe"
                rules={[
                  {
                    required: true,
                    message: 'Requerido',
                  },
                ]}
              >
                <Input type="number" disabled={stateData.object.data.id > 0} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="emisor" label="Emisor">
                <Input disabled={stateData.object.data.id > 0} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="portador" label="Portador">
                <Input disabled={stateData.object.data.id > 0} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="observacion" label="Observación">
                <Input.TextArea
                  rows={4}
                  disabled={stateData.object.data.id > 0}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cheque, chequeTipo, banco } = state;
  const stateData = cheque;
  return {
    stateData,
    chequeTipo,
    banco,
  };
};
export default connect(mapStateToProps)(ChequeEditor);
