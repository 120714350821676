import React, { useEffect, useState } from 'react';
import { Modal, Card, Space, Layout, Avatar, Select } from 'antd';
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  InteractionOutlined,
  EditFilled,
  DeleteFilled,
} from '@ant-design/icons';
import styled from 'styled-components';
import MetaTable from '../base/MetaTable';
import CuotaEditor from './CuotaEditor';
import dayjs from 'dayjs';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { convertDate } from '../../utils/funtions';
import { Content } from 'antd/lib/layout/layout';
import { connect } from 'react-redux';

const { confirm } = Modal;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const CuotaList = ({
  formaPagoId,
  onChange,
  dataSource,
  formaPago,
  onChangeFormaPago,
}) => {
  const formatDisplay = 'DD/MM/YYYY';

  const [columns, setColumns] = useState([
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Space size="middle">
            <EditFilled
              onClick={() => handleEdit(record)}
              style={{
                pointerEvents:
                  record.estado_cobro && record.estado_cobro.id !== 1
                    ? 'none'
                    : 'auto',
                opacity:
                  record.estado_cobro && record.estado_cobro.id !== 1 ? 0.5 : 1,
              }}
            />
            <DeleteFilled
              onClick={() => handleConfirmDelete(record)}
              style={{
                pointerEvents:
                  record.estado_cobro && record.estado_cobro.id !== 1
                    ? 'none'
                    : 'auto',
                opacity:
                  record.estado_cobro && record.estado_cobro.id !== 1 ? 0.5 : 1,
              }}
            />
          </Space>
        );
      },
      width: 70,
    },

    {
      width: 100,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 150,
      title: 'Importe',
      dataIndex: 'importe',
    },
    {
      width: 150,
      title: 'Usado',
      dataIndex: 'usado',
      render: (text, record) => (record.usado ? 'SI' : 'NO'),
    },
    {
      width: 400,
      title: 'Estado de Cobro',
      dataIndex: 'estado',
      render: (text, record) =>
        record.estado_cobro ? record.estado_cobro.nombre : '',
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowEdit, setRowEdit] = useState(null);
  const [showEdit, setShowEdit] = useState(null);
  const [formPago, setFormaPago] = useState(null);

  const handleSave = (data) => {
    if (rowEdit) {
      editRow(data);
    } else {
      addRow(data);
    }

    setShowEdit(false);
    setRowEdit(null);
  };

  const addRow = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(
      formatDisplay
    );

    data.key = rows.length ? rows[rows.length - 1].key + 1 : 0;
    data.estado_cobro_id = 1;
    data.usado = data.usado ? true : false;
    const newArray = [...rows, data];

    setRows(newArray);
  };

  const editRow = (data) => {
    const newArray = [
      ...rows.map((item) => {
        if (item.key === data.key) {
          return {
            ...item,
            ...data,
            usado: data.usado ? true : false,
            fecha: dayjs(new Date(data.fecha).toISOString()).format(
              formatDisplay
            ),
          };
        } else {
          return item;
        }
      }),
    ];
    setRows(newArray);
  };

  const handleEdit = (value) => {
    let data = cloneDeep(value);

    if (data) {
      data.fecha = moment(moment(convertDate(data.fecha)));
    }

    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    setRows((prev) => {
      return [...prev.filter((item) => item.key !== data.key)];
    });
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    // dispatch(setPending());
  };

  const handleConfirmDelete = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    onChange(rows);
    // eslint-disable-next-line
  }, [rows]);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setRows(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    setFormaPago(formaPagoId);
  }, [formaPagoId]);

  const handleChangeFormaPago = (e) => {
    setFormaPago(e);
    onChangeFormaPago(e);
    setRows([]);
  };

  return (
    <>
      <Card
        extra={
          <>
            <Select
              onChange={handleChangeFormaPago}
              style={{ width: '200px' }}
              value={formPago}
            >
              {formaPago.list.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.nombre}
                </Select.Option>
              ))}
            </Select>

            {formPago === 2 && (
              <PlusCircleOutlined
                style={{ fontSize: '18px', marginLeft: '5px' }}
                onClick={() => handleEdit(null)}
              />
            )}
          </>
        }
        title={
          <TitleCard>
            <Avatar
              icon={<InteractionOutlined />}
              size="small"
              style={{ backgroundColor: '#4f43ba' }}
            />

            <span style={{ marginLeft: '3px' }}>Forma de Pago</span>
          </TitleCard>
        }
        style={{ marginBottom: '10px' }}
        size="small"
        bodyStyle={rows.length === 0 ? { padding: 0 } : {}}
      >
        <Layout style={{ height: '100%' }}>
          <Content>
            {rows.length > 0 && (
              <MetaTable
                dataSource={rows}
                dataColumns={columns}
                bordered={false}
                selection={false}
                cssStyle={true}
              />
            )}
          </Content>
        </Layout>
      </Card>

      {showEdit && (
        <CuotaEditor
          dataSource={rowEdit}
          onCancel={handleHideEditor}
          onCreate={handleSave}
          visible={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { formaPago } = state;
  return {
    formaPago,
  };
};
export default connect(mapStateToProps)(CuotaList);
