import React, { useState, useEffect } from 'react';
import ClienteEditor from './ClienteEditor';
import { connect } from 'react-redux';
import {
  getAll,
  setPending,
  deleted,
  setDeletedPending,
  getAllDeleted,
  restore,
  getAllByContacto,
} from '../redux/actions/clienteActions';
import { callStatus } from '../utils/constants';
import {
  // Table,
  // Input,
  Button,
  Space,
  // Card,
  Menu,
  Dropdown,
  Row,
  Col,
  Layout,
  Modal,
  Input,
} from 'antd';
import { withRouter } from 'react-router-dom';
// import Highlighter from 'react-highlight-words';
import {
  // SearchOutlined,
  EditFilled,
  DeleteFilled,
  // DownloadOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  FormatPainterFilled,
  SelectOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import RestorePopup from './RestorePopup';
import BaseBox from './base/BaseBox';
import { useHistory } from 'react-router-dom';
import MetaTable from './base/MetaTable';
import { css } from '@emotion/css';

const { confirm, error } = Modal;

const { Content, Header, Footer } = Layout;

// const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const MenuButton = styled(Button)`
  width: 100%;
  text-align: left;
`;

const ClienteList = ({ location, stateData, condicionIva, dispatch }) => {
  const [view, setView] = useState(1);
  const [showRestore, setShowRestore] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  const history = useHistory();

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const handleContactos = (data) => {
    history.push(`/clientes/cliente-contactos/${data.id}`);
  };

  const handleCtaCte = (data) => {
    history.push(`/clientes/ctacte/${data.id}`);
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 70,
      //ellipsis: true,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Nombre/Razón Social',
      dataIndex: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      search: true,
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Cuit',
      dataIndex: 'cuit',
    },
    {
      width: 250,
      ellipsis: true,
      title: 'Condición IVA',
      dataIndex: 'condicion_iva_nombre',
      onFilter: (value, record) => {
        return record.condicion_iva_id === value;
      },
      sorter: (a, b) =>
        a.condicion_iva_nombre.localeCompare(b.condicion_iva_nombre),
      filterMultiple: true,
      filters: condicionIva.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Email',
      dataIndex: 'email',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Teléfono',
      dataIndex: 'telefono',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Dirección',
      dataIndex: 'direccion',
    },

    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={(e) => handleContactos(record)}>Contactos</Button>
          <Button onClick={(e) => handleCtaCte(record)}>CtaCte</Button>
        </Space>
      ),
      width: 200,
      //ellipsis: true,
    },
  ];

  const grid = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

  const menuView = (
    <Menu>
      <Menu.Item key="1">
        <MenuButton onClick={() => setView(1)} icon={<UnorderedListOutlined />}>
          Tabla
        </MenuButton>
      </Menu.Item>
      <Menu.Item key="2">
        <MenuButton onClick={() => setView(2)} icon={<UnorderedListOutlined />}>
          Box
        </MenuButton>
      </Menu.Item>
    </Menu>
  );
  const onSearch = (value, e) => {
    if (!e.nativeEvent.type !== 'click' && value !== '') {
      if (value.length > 2) {
        dispatch(getAllByContacto(value));
      } else {
        error({
          title: 'Debe ingresar 3 letras al menos para filtrar',
          onOk() {},
        });
      }
    }
  };

  return (
    <Layout style={{ height: '100%' }}>
      <Header>
        <Input.Search
          placeholder="Buscar por contacto"
          allowClear
          onSearch={onSearch}
          style={{ width: 300, marginBottom: 10 }}
          loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
          onChange={(e) => {
            if (e.target.value.trim() === '') {
              getAll(dispatch);
            }
          }}
          className={css`
            .ant-input-affix-wrapper input.ant-input {
              text-align: left !important;
            }
          `}
        />
      </Header>
      <Content>
        {view === 1 ? (
          <MetaTable
            dataSource={stateData.list.data}
            dataColumns={columns}
            bordered={false}
            selection={false}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
            pagination={true}
          />
        ) : (
          <Row gutter={8}>
            {stateData.list.data.map((item) => {
              return (
                <Col {...grid} key={item.id} style={{ marginBottom: '10px' }}>
                  <BaseBox
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    item={item}
                    title={item.nombre}
                    subtitle={item.cuit}
                    moreInfo={item.telefono}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <ClienteEditor
          id={rowEdit.id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}

      {showRestore &&
      stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
        <RestorePopup
          columns={restoreColumns}
          data={stateData.deleted.data}
          onCancel={handleRestoreCancel}
          onOk={handleRestoreOk}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { cliente, condicionIva } = state;
  const stateData = cliente;
  return {
    stateData,
    condicionIva,
  };
};
export default connect(mapStateToProps)(withRouter(ClienteList));
