import { callStatus } from './constants';

export const getListDataAction = ({ dataName, api, relUrl }) => {
  return (dispatch) => {
    dispatch(createListDataInProcess(dataName)());
    return api
      .get(relUrl)
      .then((response) => {
        dispatch(createListDataSuccess(dataName)(response.data));
      })
      .catch((error) => {
        dispatch(createListDataFailed(dataName)(error));
      });
  };
};

export const getListByParentDataAction = ({ dataName, api, relUrl }) => {
  return (dispatch) => {
    dispatch(createListDataInProcess(dataName)());
    return api
      .get(`/${relUrl}`)
      .then((response) => {
        dispatch(createListDataSuccess(dataName)(response.data));
      })
      .catch((error) => {
        dispatch(createListDataFailed(dataName)(error));
      });
  };
};

export const getObjectDataAction = ({ dataName, api, relUrl }) => {
  return (dispatch) => {
    dispatch(createObjectDataInProcess(dataName)());
    return api
      .get(`/${relUrl}`)
      .then((response) => {
        dispatch(createObjectDataSuccess(dataName)(response.data));
      })
      .catch((error) => {
        dispatch(createObjectDataFailed(dataName)(error));
      });
  };
};

export const saveObjectDataAction = ({ dataName, api, relUrl, data }) => {
  return (dispatch) => {
    dispatch(createObjectDataInProcess(dataName)());
    return saveData(api, data, relUrl)
      .then((response) => {
        dispatch(createObjectDataSaveSuccess(dataName)(response.data.data));

        if (data.id) {
          dispatch(createListDataUpdate(dataName)(response.data.data));
        } else {
          dispatch(createListDataAdd(dataName)(response.data.data));
        }
        // dispatch(getListDataAction({ dataName, api, relUrl }));
      })
      .catch((error) => {
        dispatch(createObjectDataFailed(dataName)(error));
      });
  };
};

export const deleteObjectDataAction = ({ dataName, api, relUrl }) => {
  return (dispatch) => {
    dispatch(createObjectDataInProcess(dataName)());
    return api
      .delete(`/${relUrl}`)
      .then((response) => {
        // dispatch(getListDataAction({ dataName, api, relUrl }));
        dispatch(createListDataRemove(dataName)(relUrl));
        // dispatch(createObjectDataPending(dataName));
      })
      .catch((error) => {
        dispatch(createObjectDataFailed(dataName)(error));
      });
  };
};

const saveData = async (api, data, relUrl) => {
  if (!data.id) {
    return await api.post(null, data);
  } else {
    return await api.put(`${relUrl}/${data.id}`, data);
  }
};

export const getListDeletedDataAction = ({ dataName, api, relUrl }) => {
  return (dispatch) => {
    dispatch(createListDataDeletedInProcess(dataName));
    return api
      .get(relUrl)
      .then((response) => {
        dispatch(createListDataDeletedSuccess(dataName)(response.data));
      })
      .catch((error) => {
        dispatch(createListDataDeletedFailed(dataName)(error));
      });
  };
};

export const restoreObjectDataAction = ({ dataName, api, relUrl, data }) => {
  return (dispatch) => {
    dispatch(createObjectDataInProcess(dataName)());
    return api
      .post(relUrl, data)
      .then((response) => {
        dispatch(getListDataAction({ dataName, api, relUrl: '' }));
      })
      .catch((error) => {
        dispatch(createObjectDataFailed(dataName)(error));
      });
  };
};

export const createListDataPending = (dataName) => {
  return () => {
    return { type: `${dataName}_${callStatus.LIST_PENDING}` };
  };
};

export const createListDataInProcess = (dataName) => {
  return () => {
    return { type: `${dataName}_${callStatus.LIST_IN_PROCESS}` };
  };
};

export const createListDataSuccess = (dataName) => {
  return (data) => {
    return { type: `${dataName}_${callStatus.LIST_SUCCESS}`, data: data };
  };
};

export const createListDataFailed = (dataName) => {
  return (error) => {
    return { type: `${dataName}_${callStatus.LIST_FAILED}`, error: error };
  };
};

export const createListDataRemove = (dataName) => {
  return (data) => {
    return { type: `${dataName}_${callStatus.LIST_REMOVE}`, data: data };
  };
};

export const createListDataUpdate = (dataName) => {
  return (data) => {
    return { type: `${dataName}_${callStatus.LIST_UPDATE}`, data: data };
  };
};

export const createListDataAdd = (dataName) => {
  return (data) => {
    return { type: `${dataName}_${callStatus.LIST_ADD}`, data: data };
  };
};

export const createObjectDataPending = (dataName) => {
  return () => {
    return { type: `${dataName}_${callStatus.OBJECT_PENDING}` };
  };
};

export const createObjectDataInProcess = (dataName) => {
  return () => {
    return { type: `${dataName}_${callStatus.OBJECT_IN_PROCESS}` };
  };
};

export const createObjectDataSuccess = (dataName) => {
  return (data) => {
    return { type: `${dataName}_${callStatus.OBJECT_SUCCESS}`, data: data };
  };
};

export const createObjectDataSaveSuccess = (dataName) => {
  return (data) => {
    return {
      type: `${dataName}_${callStatus.OBJECT_SAVE_SUCCESS}`,
      data: data,
    };
  };
};

export const createObjectDataFailed = (dataName) => {
  return (error) => {
    return { type: `${dataName}_${callStatus.OBJECT_FAILED}`, error: error };
  };
};

export const createListDataDeletedPending = (dataName) => {
  return () => {
    return { type: `${dataName}_${callStatus.LIST_DELETED_PENDING}` };
  };
};

export const createListDataDeletedInProcess = (dataName) => {
  return () => {
    return { type: `${dataName}_${callStatus.LIST_DELETED_IN_PROCESS}` };
  };
};

export const createListDataDeletedSuccess = (dataName) => {
  return (data) => {
    return {
      type: `${dataName}_${callStatus.LIST_DELETED_SUCCESS}`,
      data: data,
    };
  };
};

export const createListDataDeletedFailed = (dataName) => {
  return (error) => {
    return {
      type: `${dataName}_${callStatus.LIST_DELETED_FAILED}`,
      error: error,
    };
  };
};
