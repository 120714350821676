import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, save } from '../redux/actions/chequeActions';
import { callStatus } from '../utils/constants';
import { compareObject } from '../utils/funtions';
import { getAll as bancos } from '../redux/actions/bancoActions';

const { confirm, error, success } = Modal;

const ChequeEditor = ({
  dispatch,
  id,
  stateData,
  visible,
  onCancel,
  chequeEstado,
  chequeTipo,
  banco,
}) => {
  const [form] = Form.useForm();
  const formatDisplay = 'DD/MM/YYYY';
  const format = 'YYYY-MM-DD';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    bancos(dispatch);

    // eslint-disable-next-line
  }, []);

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      data['fecha'] = moment(moment(data.fecha));
      data['fecha_cobro'] = moment(moment(data.fecha_cobro));
      if (data.cobro) {
        data['cobro_numero'] = data.cobro.numero;
        data['cobro_cliente'] = data.cobro.cliente.nombre;
      }
      if (data.pago) {
        data['pago_numero'] = data.pago.numero;
        data['pago_proveedor'] = data.pago.proveedor.nombre;
      }
      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage('El Cheque se guardó con éxito', 'success', onCancel);
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);
    data.fecha_cobro = dayjs(new Date(data.fecha_cobro).toISOString()).format(
      format
    );

    dispatch(save(data));
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={stateData.object.data.id ? 'Editar Cheque' : 'Nuevo Cheque'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="cobro_numero" label="Cobro">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="cobro_cliente" label="Cliente">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="pago_numero" label="Pago">
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="pago_proveedor" label="Proveedor">
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="numero"
                  label="Numero"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="tipo_id" label="Tipo">
                  <Select>
                    {chequeTipo.list.data.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="fecha"
                  label="Fecha"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <DatePicker
                    format={formatDisplay}
                    style={{ width: '100%' }}
                    initialValue={moment(new Date())}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="fecha_cobro"
                  label="Fecha Cobro"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <DatePicker
                    format={formatDisplay}
                    style={{ width: '100%' }}
                    initialValue={moment(new Date())}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="banco_id"
                  label="Banco"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {banco.list.data.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="importe" label="Importe">
                  <Input type="number" readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="emisor" label="Emisor">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item name="portador" label="Portador">
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="estado_id"
                  label="Estado"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {chequeEstado.list.data.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="observacion" label="Observación">
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cheque, chequeEstado, chequeTipo, banco } = state;
  const stateData = cheque;
  return {
    stateData,
    chequeEstado,
    chequeTipo,
    banco,
  };
};
export default connect(mapStateToProps)(ChequeEditor);
