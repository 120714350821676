import { comprobanteApi } from '../../utils/api';
import {
  getObjectDataAction,
  createObjectDataPending,
  getListDataAction,
  saveObjectDataAction,
  deleteObjectDataAction,
  getListDeletedDataAction,
  createListDataDeletedPending,
  restoreObjectDataAction,
  getListByParentDataAction,
  createObjectDataInProcess,
  createObjectDataSaveSuccess,
  createObjectDataFailed,
  createListDataUpdate,
} from '../../utils/DataAction';

export const get = (id) =>
  getObjectDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: id,
  });

export const getAfip = (id) =>
  getObjectDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: `getPersona/${id}`,
  });

export const getAll = getListDataAction({
  dataName: 'COMPROBANTE',
  api: comprobanteApi,
  relUrl: '',
});

export const getAllByParent = (id) =>
  getListByParentDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: `indexByParent/${id}`,
  });

export const save = (data) =>
  saveObjectDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: '',
    data: data,
  });

export const transform = (data) =>
  saveObjectDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: 'transformAfip',
    data: data,
  });

export const deleted = (id) =>
  deleteObjectDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: id,
  });

export const getAllDeleted = getListDeletedDataAction({
  dataName: 'COMPROBANTE',
  api: comprobanteApi,
  relUrl: 'restore',
});

export const restore = (data) =>
  restoreObjectDataAction({
    dataName: 'COMPROBANTE',
    api: comprobanteApi,
    relUrl: 'restore',
    data: data,
  });

// export const restoreAfip = (id) =>
// saveObjectDataAction({
//     dataName: 'COMPROBANTE',
//     api: comprobanteApi,
//     relUrl: `restoreAfip/${id}`,
//   });

export const restoreAfip = (id) => {
  return (dispatch) => {
    dispatch(createObjectDataInProcess('COMPROBANTE')());
    return comprobanteApi
      .get(`restoreAfip/${id}`)
      .then((response) => {
        dispatch(
          createObjectDataSaveSuccess('COMPROBANTE')(response.data.data)
        );
        dispatch(createListDataUpdate('COMPROBANTE')(response.data.data));
      })
      .catch((error) => {
        dispatch(createObjectDataFailed('COMPROBANTE')(error));
      });
  };
};

export const setPending = createObjectDataPending('COMPROBANTE');
export const setDeletedPending = createListDataDeletedPending('COMPROBANTE');
