import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Modal, Row, Space } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import MetaTable from '../base/MetaTable';
import ProductoDepositoEditor from './ProductoDepositoEditor';
const { confirm, error } = Modal;

const ProductoDepositoList = ({ id, config, onChange, dataSource }) => {
  const [columns] = useState([
    {
      title: '',
      key: 'action',
      dataIndex: 'action',

      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={() => handleEdit(record)} />
          <DeleteFilled onClick={() => handleConfirmDelete(record)} />
        </Space>
      ),
      width: 70,
    },
    {
      width: 150,
      title: 'Ubicación',
      dataIndex: 'ubicacion_nombre',
    },
    {
      width: 150,
      title: 'Depósito',
      dataIndex: 'deposito_nombre',
    },
    {
      width: 100,
      title: 'Cant',
      dataIndex: 'cantidad',
    },
    {
      width: 100,
      title: 'Cant Min',
      dataIndex: 'cantidad_minima',
    },
    {
      width: 80,
      title: `${
        config.length > 0
          ? config.find((item) => item.nombre === 'label_posicion_x').valor
          : 'Pos X'
      }`,
      dataIndex: 'posicion_x',
    },
    {
      width: 80,
      title: `${
        config.length > 0
          ? config.find((item) => item.nombre === 'label_posicion_y').valor
          : 'Pos Y'
      }`,
      dataIndex: 'posicion_y',
    },
    {
      width: 100,
      title: `${
        config.length > 0
          ? config.find((item) => item.nombre === 'label_posicion_z').valor
          : 'Pos Z'
      }`,
      dataIndex: 'posicion_z',
    },
  ]);
  const [rows, setRows] = useState([]);
  const [rowEdit, setRowEdit] = useState(null);
  const [showEdit, setShowEdit] = useState(null);

  const handleSave = (data) => {
    if (rowEdit) {
      const result = rows.filter(
        (item) => item.deposito_id === data.deposito_id && item.id !== data.id
      );
      if (result.length > 0) {
        error({
          title: 'Este depósito ya fue ingresado',
          onOk() {},
        });
      } else {
        editRow(data);
      }
    } else {
      const result = rows.filter(
        (item) => item.deposito_id === data.deposito_id
      );
      if (result.length > 0) {
        error({
          title: 'Este depósito ya fue ingresado',
          onOk() {},
        });
      } else {
        addRow(data);
      }
    }

    setShowEdit(false);
    setRowEdit(null);
  };

  const addRow = (data) => {
    data.key = rows.length ? rows[rows.length - 1].key + 1 : 0;
    const newArray = [...rows, data];
    setRows(newArray);
  };

  const editRow = (data) => {
    const newArray = [
      ...rows.map((item) => {
        if (item.key === data.key) {
          return {
            ...data,
          };
        } else {
          return item;
        }
      }),
    ];
    setRows(newArray);
  };

  const handleEdit = (value) => {
    let data = cloneDeep(value);

    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    setRows((prev) => {
      return [...prev.filter((item) => item.key !== data.key)];
    });
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    // dispatch(setPending());
  };

  const handleConfirmDelete = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    onChange(rows);
    // eslint-disable-next-line
  }, [rows]);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setRows(dataSource);
    }
  }, [dataSource]);

  // useEffect(() => {
  //   if (id) {
  //     setColumns((prev) => {
  //       return prev.filter((item) => item.dataIndex !== 'action');
  //     });
  //   }
  // }, [id]);

  return (
    <>
      <Row justify="end" style={{ marginBottom: '7px' }}>
        <PlusCircleOutlined
          style={{ fontSize: '18px' }}
          onClick={() => handleEdit(null)}
        />
      </Row>

      <Row>
        {rows.length > 0 && (
          <MetaTable
            dataSource={rows}
            dataColumns={columns}
            bordered={false}
            selection={false}
            cssStyle={true}
          />
        )}
      </Row>

      {showEdit && (
        <ProductoDepositoEditor
          dataSource={rowEdit}
          onCancel={handleHideEditor}
          onCreate={handleSave}
          visible={true}
        />
      )}
    </>
  );
};

export default ProductoDepositoList;
