import { callStatus } from './constants';

export const createReducer = ({ dataName = '' }) => {
  const initialState = {
    list: {
      data: [],
      status: callStatus.LIST_PENDING,
      error: '',
    },
    object: {
      data: {},
      status: callStatus.OBJECT_PENDING,
      error: '',
    },
    deleted: {
      data: {},
      status: callStatus.LIST_DELETED_PENDING,
      error: '',
    },
  };
  return (state = initialState, action) => {
    switch (action.type) {
      case `${dataName}_${callStatus.LIST_IN_PROCESS}`: {
        const newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_IN_PROCESS,
            data: [],
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.LIST_SUCCESS}`: {
        let newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_SUCCESS,
            data: action.data,
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.LIST_FAILED}`: {
        const newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_FAILED,
            data: [],
            error: action.error,
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.LIST_PENDING}`: {
        const newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_PENDING,
            data: [],
            error: '',
          },
        });
        return newState;
      }

      case `${dataName}_${callStatus.LIST_REMOVE}`: {
        const newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_SUCCESS,
            data: state.list.data.filter((item) => item.id !== action.data),
            error: '',
          },
          object: {
            status: callStatus.OBJECT_PENDING,
            data: {},
            error: '',
          },
        });

        return newState;
      }

      case `${dataName}_${callStatus.LIST_ADD}`: {
        const newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_SUCCESS,
            data: [...state.list.data, action.data],
            error: '',
          },
        });

        return newState;
      }

      case `${dataName}_${callStatus.LIST_UPDATE}`: {
        const newState = Object.assign({}, state, {
          list: {
            status: callStatus.LIST_SUCCESS,
            data: state.list.data.map((item) =>
              item.id === action.data.id ? action.data : item
            ),
            error: '',
          },
        });

        return newState;
      }

      case `${dataName}_${callStatus.OBJECT_IN_PROCESS}`: {
        const newState = Object.assign({}, state, {
          object: {
            status: callStatus.OBJECT_IN_PROCESS,
            data: {},
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.OBJECT_SUCCESS}`: {
        let newState = Object.assign({}, state, {
          object: {
            status: callStatus.OBJECT_SUCCESS,
            data: action.data,
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.OBJECT_SAVE_SUCCESS}`: {
        let newState = Object.assign({}, state, {
          object: {
            status: callStatus.OBJECT_SAVE_SUCCESS,
            data: action.data,
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.OBJECT_FAILED}`: {
        const newState = Object.assign({}, state, {
          object: {
            status: callStatus.OBJECT_FAILED,
            data: {},
            error: action.error,
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.OBJECT_PENDING}`: {
        const newState = Object.assign({}, state, {
          object: {
            status: callStatus.OBJECT_PENDING,
            data: {},
            error: '',
          },
        });
        return newState;
      }

      case `${dataName}_${callStatus.LIST_DELETED_IN_PROCESS}`: {
        const newState = Object.assign({}, state, {
          deleted: {
            status: callStatus.LIST_DELETED_IN_PROCESS,
            data: [],
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.LIST_DELETED_SUCCESS}`: {
        let newState = Object.assign({}, state, {
          deleted: {
            status: callStatus.LIST_DELETED_SUCCESS,
            data: action.data,
            error: '',
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.LIST_DELETED_FAILED}`: {
        const newState = Object.assign({}, state, {
          deleted: {
            status: callStatus.LIST_DELETED_FAILED,
            data: [],
            error: action.error,
          },
        });
        return newState;
      }
      case `${dataName}_${callStatus.LIST_DELETED_PENDING}`: {
        const newState = Object.assign({}, state, {
          deleted: {
            status: callStatus.LIST_DELETED_PENDING,
            data: [],
            error: '',
          },
        });
        return newState;
      }

      default: {
        return state;
      }
    }
  };
};
