import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
});

const EncabezadoCliente = ({ data, qr, model = 'venta' }) => {
  return (
    <View
      fixed
      style={{
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingBottom: 5,
        marginTop: '10px',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {model === 'venta' ? (
          <>
            <Text style={styles.contacto}>{data.cliente_nombre}</Text>
            <Text style={styles.contacto}>CUIT: {data.cliente.cuit}</Text>
            <Text style={styles.contacto}>{data.cliente.direccion}</Text>
          </>
        ) : (
          <>
            <Text style={styles.contacto}>{data.proveedor_nombre}</Text>
            <Text style={styles.contacto}>CUIT: {data.proveedor.cuit}</Text>
            <Text style={styles.contacto}>{data.proveedor.direccion}</Text>
          </>
        )}
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text style={styles.contacto}>
          Condición IVA:{' '}
          {model === 'venta'
            ? data.cliente_condicion_iva
            : data.proveedor_condicion_iva}
        </Text>
        {qr && (
          <Text style={styles.contacto}>
            Condición Vta: {data.condicion_venta.nombre}
          </Text>
        )}
      </View>
    </View>
  );
};

export default EncabezadoCliente;
