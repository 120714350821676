import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Row, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, transform } from '../redux/actions/comprobanteActions';
import { callStatus } from '../utils/constants';
import { compareObject } from '../utils/funtions';
const { confirm, error, success } = Modal;

const ComprobanteTransformEditor = ({
  dispatch,
  id,
  stateData,
  comprobanteTipo,
  puntoVenta,
  visible,
  onCancel,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);
  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      form.setFieldsValue({ id: stateData.object.data.id });
      setInitial({ id: stateData.object.data.id });
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage(
        'El comprobante se guardó con éxito',
        'success',
        onCancel
      );
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const onCreate = (data) => {
    dispatch(transform(data));
  };

  const getTipos = (id) => {
    const tipoId = stateData.object.data.tipo_id;

    if (tipoId === -5) {
      return id === 1 || id === 6 || id === 11 ? true : false;
    }

    if (tipoId === -6) {
      return id === 3 || id === 8 || id === 13 ? true : false;
    }

    if (tipoId === -7) {
      return id === 2 || id === 7 || id === 12 ? true : false;
    }
  };

  const handleConfirm = (data) => {
    confirm({
      title: 'Esta a punto de convertir un Comprobante, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        onCreate(data);
      },
      onCancel() {},
    });
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={'Convertir Comprobante'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleConfirm(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]} style={{ marginBottom: '10px' }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span style={{ fontSize: '16px' }}>
                  <b>
                    {' '}
                    {stateData.object.data.tipo?.nombre}{' '}
                    {stateData.object.data.tipo?.letra}{' '}
                    {stateData.object.data.numero}
                  </b>
                </span>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="punto_venta_id"
                  label="Punto de Venta"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {puntoVenta.list.data
                      .filter((item) => item.id > 1)
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {`${item.nombre} - ${item.descripcion}`}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="tipo_id"
                  label="Tipo"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {comprobanteTipo.list.data
                      .filter((item) => getTipos(item.id))
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.letra
                            ? item.nombre + ' ' + item.letra
                            : item.nombre}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="observacion">
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { comprobante, comprobanteTipo, puntoVenta } = state;
  const stateData = comprobante;
  return {
    stateData,
    comprobanteTipo,
    puntoVenta,
  };
};
export default connect(mapStateToProps)(ComprobanteTransformEditor);
