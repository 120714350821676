import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { callStatus } from '../utils/constants';
import { Modal, Form, Input, Row, Col, Select, Spin } from 'antd';
import { get, save } from '../redux/actions/productoDepositoActions';
import { getAll as productos } from '../redux/actions/productoActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
const { confirm, error, success } = Modal;
const { Option } = Select;

const ProductoDepositoEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  producto,
  configuracion,
}) => {
  const [form] = Form.useForm();
  const [resultSearch, setResultSearch] = useState([]);
  const [productoList, setProductoList] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    productos(dispatch);

    // eslint-disable-next-line
  }, []);

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      form.setFieldsValue(stateData.object.data);
      if (stateData.object.data.producto) {
        setResultSearch([stateData.object.data.producto]);
      }
      setInitial(stateData.object.data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage(
        'El Producto/Deposito se guardó con éxito',
        'success',
        onCancel
      );
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  useEffect(() => {
    setProductoList(producto.list.data);
    // eslint-disable-next-line
  }, [producto]);

  const onCreate = (data) => {
    data.deposito_id = parentId;
    dispatch(save(data));
  };

  const onSearch = (value) => {
    const result = productoList.filter(
      (item) =>
        (item.nombre &&
          item.nombre.toLowerCase().includes(value.toLowerCase())) ||
        (item.codigo && item.codigo.toLowerCase().includes(value.toLowerCase()))
    );

    setResultSearch(result);
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={
          stateData.object.data.id
            ? 'Editar ProductoDeposito'
            : 'Nuevo ProductoDeposito'
        }
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="producto_id"
                  label="Producto"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={onSearch}
                    onChange={() => {}}
                    notFoundContent={null}
                    allowClear
                  >
                    {resultSearch.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.codigo
                          ? `${item.codigo} - ${item.nombre}`
                          : item.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="cantidad"
                  label="Cantidad"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item name="cantidad_minima" label="Cantidad mínima">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="posicion_x"
                  label={
                    configuracion.list.data.find(
                      (item) => item.nombre === 'label_posicion_x'
                    ).valor
                  }
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="posicion_y"
                  label={
                    configuracion.list.data.find(
                      (item) => item.nombre === 'label_posicion_y'
                    ).valor
                  }
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item
                  name="posicion_z"
                  label={
                    configuracion.list.data.find(
                      (item) => item.nombre === 'label_posicion_z'
                    ).valor
                  }
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { productoDeposito, producto, configuracion } = state;
  const stateData = productoDeposito;
  return {
    stateData,
    producto,
    configuracion,
  };
};
export default connect(mapStateToProps)(ProductoDepositoEditor);
