import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Layout, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  deleted,
  getAll,
  restore,
  setDeletedPending,
  setPending,
} from '../redux/actions/chequeActions';
import { callStatus } from '../utils/constants';
import { convertDate } from '../utils/funtions';
import ChequeEditor from './ChequeEditor';
import RestorePopup from './RestorePopup';
import CustomCellRender from './base/CustomCellRender';
import MetaTable from './base/MetaTable';

const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const ChequeList = ({
  location,
  stateData,
  chequeEstado,
  chequeTipo,
  dispatch,
}) => {
  const [showRestore, setShowRestore] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  const formatDisplay = 'DD/MM/YYYY';
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.list.status === callStatus.LIST_SUCCESS) {
      const data = [
        ...stateData.list.data.map((item) => {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            fecha_cobro: dayjs(
              new Date(
                moment(moment(convertDate(item.fecha_cobro)))
              ).toISOString()
            ).format(formatDisplay),
          };
        }),
      ];

      setDataSource(data);
    }

    // eslint-disable-next-line
  }, [stateData]);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          <DeleteFilled onClick={(e) => handleConfirm(record)} />
        </Space>
      ),
      width: 60,
      // ellipsis: true,
    },
    {
      width: 120,
      ellipsis: true,
      title: 'Estado',
      dataIndex: 'estado_nombre',
      onFilter: (value, record) => {
        return record.estado_id === value;
      },
      sorter: (a, b) => a.estado_nombre.localeCompare(b.estado_nombre),
      filterMultiple: true,
      filters: chequeEstado.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Días venc',
      dataIndex: 'dias',
      sorter: (a, b) => a.dias - b.dias,
      render: (text, record) => {
        if (record.estado_id === 1) {
          if (text <= 10 && text >= 0) {
            return (
              <span style={{ fontWeight: 'bold', color: 'red' }}>{text}</span>
            );
          } else if (text <= 30 && text > 10) {
            return (
              <span style={{ fontWeight: 'bold', color: 'green' }}>{text}</span>
            );
          } else if (text < 0) {
            return (
              <span style={{ fontWeight: 'bold', color: 'black' }}>{text}</span>
            );
          } else {
            return <span style={{ color: 'black' }}>{text}</span>;
          }
        } else {
          return '';
        }
      },
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Numero',
      dataIndex: 'numero',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      search: true,
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Importe',
      dataIndex: 'importe',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 130,
      ellipsis: true,
      title: 'Cobro',
      dataIndex: 'cobro',
      render: (value) => {
        return value ? value.numero : '';
      },
    },
    {
      width: 130,
      ellipsis: true,
      title: 'Pago',
      dataIndex: 'pago',
      render: (value) => {
        return value ? value.numero : '';
      },
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha Cobro',
      dataIndex: 'fecha_cobro',
    },
    {
      width: 180,
      ellipsis: true,
      title: 'Banco',
      dataIndex: 'banco_nombre',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Emisor',
      dataIndex: 'emisor',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Portador',
      dataIndex: 'portador',
    },
    {
      width: 120,
      ellipsis: true,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
      onFilter: (value, record) => {
        return record.tipo_id === value;
      },
      sorter: (a, b) => a.tipo_nombre.localeCompare(b.tipo_nombre),
      filterMultiple: true,
      filters: chequeTipo.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
    },
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        <MetaTable
          dataSource={dataSource}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
          pagination={true}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            {/* <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>*/}
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <ChequeEditor
          id={rowEdit.id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}

      {showRestore &&
      stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
        <RestorePopup
          columns={restoreColumns}
          data={stateData.deleted.data}
          onCancel={handleRestoreCancel}
          onOk={handleRestoreOk}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { cheque, chequeEstado, chequeTipo } = state;
  const stateData = cheque;
  return {
    stateData,
    chequeEstado,
    chequeTipo,
  };
};
export default connect(mapStateToProps)(withRouter(ChequeList));
