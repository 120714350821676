import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from '../../redux/actions/comprobanteCompraActions';
import { Page, View, Document, PDFViewer } from '@react-pdf/renderer';
import { Modal } from 'antd';
import { callStatus } from '../../utils/constants';
import cloneDeep from 'lodash/cloneDeep';
import ComprobanteViewerWithoutItems from './ComprobanteViewerWithoutItems';
import ComprobanteViewerItems from './ComprobanteViewerItems';
import EncabezadoEmpresa from './EncabezadoEmpresa';
import Encabezado from './Encabezado';
import EncabezadoCliente from './EncabezadoCliente';
import PieTotales from './PieTotales';
import ComprobanteObservacion from './ComprobanteObservacion';

const MyDocument = ({ stateData, config }) => {
  let data = cloneDeep(stateData);

  if (data.tipo_id !== 1 && data.tipo_id !== 3) {
    data.items = data.items.map((item, idx) => {
      const precio = (
        parseFloat(item.precio) +
        (parseFloat(item.precio) * parseFloat(item.alicuota)) / 100
      ).toFixed(2);
      return { ...item, precio: precio };
    });
  }

  // data.items = [
  //   ...data.items.map((item) => {
  //     if (item.parent_id) {
  //       return {
  //         ...item,
  //         precio: '',
  //         subtotal: '',
  //         descuento: '',
  //         alicuota: '',
  //         cantidad:
  //           data.tipo_id === -3 || data.tipo_id === -2 ? item.cantidad : '',
  //       };
  //     } else {
  //       return { ...item };
  //     }
  //   }),
  // ];

  return (
    <Document size="A4">
      <Page style={{ padding: 15 }} wrap>
        <Encabezado data={data} type="comprobante" model="compra" />

        <EncabezadoEmpresa data={config} />

        <EncabezadoCliente data={data} model="compra" />

        <View style={{ flex: 1, flexDirection: 'column' }}>
          {data.items.length > 0 ? (
            <ComprobanteViewerItems data={data} />
          ) : (
            <ComprobanteViewerWithoutItems data={data} />
          )}
        </View>

        {data.items.length > 0 && data.observacion && (
          <ComprobanteObservacion data={data} />
        )}

        {data.tipo_id !== -3 && <PieTotales data={data} />}
      </Page>
    </Document>
  );
};

const ComprobanteCompraViewer = ({
  dispatch,
  stateData,
  configData,
  id,
  visible,
  onCancel,
}) => {
  const [loadPDF, setLoadPDF] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      stateData.object.status === callStatus.OBJECT_SUCCESS &&
      configData.list.status === callStatus.LIST_SUCCESS
    ) {
      setLoadPDF(true);
    }
  }, [stateData, configData]);

  return (
    <Modal
      centered
      maskClosable={false}
      visible={visible}
      title="Impresión Comprobante"
      onCancel={onCancel}
      width={700}
      bodyStyle={{ padding: 0, background: '#3a3d3f ' }}
      footer={null}
    >
      {loadPDF && (
        <PDFViewer
          style={{
            width: '100%',
            height: '400px',
            border: 'none',
            margin: 0,
            padding: 0,
            marginTop: '-2px',
          }}
        >
          <MyDocument
            stateData={stateData.object.data}
            config={configData.list.data}
          />
        </PDFViewer>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const { comprobanteCompra, configuracion } = state;
  const stateData = comprobanteCompra;
  const configData = configuracion;
  return {
    stateData,
    configData,
  };
};
export default connect(mapStateToProps)(ComprobanteCompraViewer);
