import {
  CalendarOutlined,
  CloseCircleOutlined,
  DeleteFilled,
  DollarOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  FormOutlined,
  PlusCircleOutlined,
  UserOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
} from 'antd';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getAll as listas } from '../redux/actions/listaActions';
import { get, save, setPending } from '../redux/actions/pagoActions';
import {
  getAll as proveedores,
  setPending as setPendingProveedor,
} from '../redux/actions/proveedorActions';
import { callStatus } from '../utils/constants';
import { compareObject, convertDate } from '../utils/funtions';
import ChequeList from './Cheque/ChequeList';
import EjecucionesAsociadasPopup from './EjecucionesAsociadasPopup';
import FacturasAsociadasPopup from './FacturasAsociadasPopup';
import ProveedorEditor from './ProveedorEditor';
import RetencionList from './Retencion/RetencionList';
import TarjetaList from './Tarjeta/TarjetaList';
import TransferenciaList from './Transferencia/TransferenciaList';
import MetaTable from './base/MetaTable';
import { getAll as ubicaciones } from '../redux/actions/ubicacionActions';

const { confirm, info, error, success } = Modal;
const { Option } = Select;

const { Content, Header, Footer } = Layout;

const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;

const PagoEditor = ({
  dispatch,
  stateData,
  visible,
  onCancel,
  proveedor,
  configuracion,
  cobroTipo,
  ubicacion,
  match: {
    params: { id },
  },
}) => {
  const [form] = Form.useForm();

  const [tipoId, setTipoId] = useState(1);
  const [initial, setInitial] = useState({});

  const [showEditProveedor, setShowEditProveedor] = useState(false);
  const [showAddAsociados, setShowAddAsociados] = useState(false);
  const [showAddEjecuciones, setShowAddEjecuciones] = useState(false);

  const [proveedorId, setProveedorId] = useState(null);

  const [resultSearchProveedor, setResultSearchProveedor] = useState([]);
  const [proveedorList, setProveedorList] = useState([]);

  const [retenciones, setRetenciones] = useState([]);
  const [asociados, setAsociados] = useState([]);
  const [ejecuciones, setEjecuciones] = useState([]);

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  const history = useHistory();

  const proveedorRef = useRef(null);

  useEffect(() => {
    if (id && id !== '0') {
      dispatch(get(id));
    }

    listas(dispatch);
    proveedores(dispatch);
    ubicaciones(dispatch);

    // eslint-disable-next-line
  }, []);

  const [columnsAsociado, setColumnsAsociado] = useState([
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteFilled onClick={() => handleConfirmDeleteAsociado(record)} />
        </Space>
      ),
      width: 30,
    },
    {
      width: 150,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      width: 100,
      title: 'Numero',
      dataIndex: 'numero',
    },
    {
      width: 100,
      title: 'Fecha',
      dataIndex: 'fecha',
    },

    {
      width: 140,
      title: 'Total',
      dataIndex: 'total',
    },
    {
      width: 140,
      title: 'Saldo Pendiente',
      dataIndex: 'saldo',
      render: (text, record) => (
        <span style={{ fontWeight: 'bold', color: '#cf1322' }}>
          {record.saldo}
        </span>
      ),
    },
    {
      width: 140,
      title: 'Pago',
      dataIndex: 'pago',
      hidden: true,
    },
  ]);

  const [columnsEjecucion] = useState([
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteFilled onClick={() => handleConfirmDeleteEjecucion(record)} />
        </Space>
      ),
      width: 30,
    },
    {
      width: 150,
      title: 'Tipo',
      dataIndex: 'tipo_nombre',
    },
    {
      width: 100,
      title: 'Numero',
      dataIndex: 'numero',
    },
    {
      width: 100,
      title: 'Fecha',
      dataIndex: 'fecha',
    },

    {
      width: 140,
      title: 'Total',
      dataIndex: 'total',
    },
    {
      width: 140,
      title: 'Saldo Disponible',
      dataIndex: 'saldo',
      render: (text, record) => (
        <span style={{ fontWeight: 'bold', color: '#3f8600' }}>
          {record.saldo}
        </span>
      ),
    },
    {
      width: 140,
      title: 'Pago',
      dataIndex: 'pago',
      hidden: true,
    },
  ]);

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);
    if (stateData.object.status === callStatus.OBJECT_PENDING) {
      data['total'] = 0;
      data['pagar'] = 0;
      data['credito'] = 0;
      data['saldo'] = 0;
      data['diferencia'] = 0;
      data['fecha'] = moment(new Date());
      // data['fecha'] = moment(moment(data.fecha));
    } else if (
      stateData.object.status === callStatus.OBJECT_SUCCESS ||
      stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS
    ) {
      setColumnsAsociado((prev) => {
        const newColumns = prev.map((item) => {
          if (item.dataIndex === 'pago') {
            item.hidden = false;
          }
          return item;
        });

        return newColumns;
      });
      data['proveedor_nombre_completo'] = data.proveedor.cuit
        ? `${data.proveedor.cuit} - ${data.proveedor.nombre}`
        : `${data.proveedor.nombre}`;

      data['fecha'] = dayjs(
        new Date(moment(moment(convertDate(data.fecha)))).toISOString()
      ).format(formatDisplay);

      data['asociados'] = data.comprobantes.map((item) => {
        return {
          ...item.comprobante,
          fecha: dayjs(
            new Date(
              moment(moment(convertDate(item.comprobante.fecha)))
            ).toISOString()
          ).format(formatDisplay),
        };
      });
      if (data.ejecuciones) {
        data['ejecuciones'] = data.ejecuciones.map((item) => {
          return {
            ...item.ejecucion,
            fecha: dayjs(
              new Date(
                moment(moment(convertDate(item.comprobante.fecha)))
              ).toISOString()
            ).format(formatDisplay),
          };
        });
      }

      data['transferencias'] = data.transferencias.map((item) => {
        return {
          ...item,
          key: item.id,
          fecha: dayjs(
            new Date(moment(moment(convertDate(item.fecha)))).toISOString()
          ).format(formatDisplay),
        };
      });

      data['cheques'] = data.cheques.map((item) => {
        return {
          ...item,
          key: item.id,
          fecha: dayjs(
            new Date(moment(moment(convertDate(item.fecha)))).toISOString()
          ).format(formatDisplay),
          fecha_cobro: dayjs(
            new Date(
              moment(moment(convertDate(item.fecha_cobro)))
            ).toISOString()
          ).format(formatDisplay),
        };
      });

      data['tarjetas'] = data.tarjetas.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });

      data['retenciones'] = data.retenciones.map((item) => {
        return {
          ...item,
          key: item.id,
          fecha: item.fecha
            ? dayjs(
                new Date(moment(moment(convertDate(item.fecha)))).toISOString()
              ).format(formatDisplay)
            : item.fecha,
        };
      });

      const newColumns = columnsAsociado.filter(
        (item) => item.dataIndex !== 'action' && item.dataIndex !== 'saldo'
      );
      setColumnsAsociado(newColumns);

      setAsociados(data['asociados']);
      setEjecuciones(data['ejecuciones']);
      setRetenciones(data['retenciones']);

      setTipoId(stateData.object.data.tipo_id);

      if (stateData.object.data.proveedor) {
        setResultSearchProveedor([stateData.object.data.proveedor]);
        setProveedorId(stateData.object.data.proveedor_id);
      }

      if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
        handleSaveSuccess('El pago se guardó con éxito');
      }
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleSaveError(stateData.object.error);
    }
    form.setFieldsValue(data);
    setInitial(data);
    // eslint-disable-next-line
  }, [stateData.object]);

  const handlePagarComprobante = (data) => {
    let total =
      parseFloat(form.getFieldValue('total')) +
      parseFloat(form.getFieldValue('credito'));

    if (total === 0) {
      handleSaveError('Debe ingresar una Forma de pago o Crédito ha ejecutar');
    } else {
      const pagoAsociados = data.asociados.map((item) => {
        if (total > 0) {
          const pago = (parseFloat(item.saldo) - total).toFixed(2);

          if (pago > 0) {
            item['pago'] = total.toFixed(2);
            total = 0;
            item['estado_pago_id'] = 2;
            item['estado_pago_nombre'] = 'Pago parcial';
          } else {
            item['pago'] = item.saldo;
            total = total - parseFloat(item.saldo);
            item['estado_pago_id'] = 3;
            item['estado_pago_nombre'] = 'Cobrado';
          }
        } else {
          item['pago'] = 0;
          item['estado_pago_id'] = 1;
          item['estado_pago_nombre'] = 'Pendiente';
        }

        return {
          ...item,
        };
      });

      setAsociados(pagoAsociados);
      data.asociados = pagoAsociados;

      let totalEjecutar = parseFloat(form.getFieldValue('pagar'));
      if (data.ejecuciones) {
        // let totalEjecutar =
        //   parseFloat(form.getFieldValue('pagar')) -
        //   parseFloat(form.getFieldValue('total'));

        const pagoEjecuciones = data.ejecuciones.map((item) => {
          if (totalEjecutar > 0) {
            const totalEjecutarAnt = totalEjecutar;
            totalEjecutar = totalEjecutar - parseFloat(item.saldo);

            if (totalEjecutar >= 0) {
              item['pago'] = parseFloat(item.saldo).toFixed(2);
            } else {
              item['pago'] = totalEjecutarAnt.toFixed(2);
            }
          } else {
            item['pago'] = 0;
          }

          return {
            ...item,
          };
        });

        setEjecuciones(pagoEjecuciones.filter((item) => item.pago > 0));
        data.ejecuciones = pagoEjecuciones.filter((item) => item.pago > 0);
      }

      // const Cpagar = parseFloat(form.getFieldValue('pagar'));
      const Ctotal = parseFloat(form.getFieldValue('total'));

      if (totalEjecutar <= 0) {
        data.credito = Ctotal;
      } else {
        data.credito =
          (Ctotal - totalEjecutar).toFixed(2) > 0
            ? (Ctotal - totalEjecutar).toFixed(2)
            : 0;
      }

      // if (Cpagar >= Ctotal) {
      //   data.credito = 0;
      // } else {
      //   data.credito = (Ctotal - Cpagar).toFixed(2);
      // }

      data.total = Ctotal - data.credito;
      onCreate(data);
    }
  };

  const handleCrearAnticipo = (data) => {
    let total = parseFloat(form.getFieldValue('total'));
    if (total === 0) {
      handleSaveError('Debe ingresar una Forma de pago o Crédito ha ejecutar');
    } else {
      onCreate(data);
    }
  };

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);

    data.transferencias = data.transferencias.map((item) => {
      return {
        ...item,
        fecha: convertDate(item.fecha),
      };
    });

    data.cheques = data.cheques.map((item) => {
      return {
        ...item,
        fecha: convertDate(item.fecha),
        fecha_cobro: convertDate(item.fecha_cobro),
      };
    });

    data.tarjetas = data.tarjetas.map((item) => {
      return {
        ...item,
      };
    });

    if (data.retenciones) {
      data.retenciones = data.retenciones.map((item) => {
        return {
          ...item,
          fecha: item.fecha ? convertDate(item.fecha) : item.fecha,
        };
      });
    }

    dispatch(save(data));
  };

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(setPending());
          history.push(`/pagos`);
        },
        onCancel() {},
      });
    } else {
      dispatch(setPending());
      history.push(`/pagos`);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setPending());
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const unlisten = history.listen((location, action) => {
  //     dispatch(setPending());
  //     return true;
  //   });

  //   return () => {
  //     unlisten();
  //   };
  // });

  // CLIENTE /////////////////////////////////////////////////////////////

  const initProveedorValues = () => {
    setProveedorId(null);
    setResultSearchProveedor([]);
    form.setFieldsValue({
      proveedor_id: null,
      proveedor_nombre: null,
      proveedor_condicion_iva: null,
      // proveedor_credito: null,
      credito: 0,
    });
  };

  useEffect(() => {
    setProveedorList(proveedor.list.data);
    // eslint-disable-next-line
  }, [proveedor]);

  const handleOnSearchProveedorEnter = (e) => {};

  const handleOnSearchProveedorBlur = (e) => {};

  const handleHideEditorProveedor = () => {
    setShowEditProveedor(false);
    dispatch(setPendingProveedor());
  };

  useEffect(() => {
    if (proveedorId) {
      const result = proveedorList.find((item) => item.id === proveedorId);
      if (result) {
        !stateData.object.data.id && onChangeProveedor(result.id);
      }
    }
    // eslint-disable-next-line
  }, [proveedorList]);

  const onSearchProveedor = (value) => {
    if (value) {
      let result = [];
      result = proveedorList.filter(
        (item) =>
          item.nombre.toLowerCase().includes(value.toLowerCase()) ||
          (item.cuit && item.cuit.toLowerCase().includes(value.toLowerCase()))
      );
      setResultSearchProveedor(result);
    } else {
      setResultSearchProveedor([]);
    }
  };

  const onChangeProveedor = (value) => {
    const result = proveedorList.find((item) => item.id === value);

    if (result) {
      setResultSearchProveedor([{ ...result }]);

      form.setFieldsValue({
        proveedor_nombre: result.nombre,
        proveedor_condicion_iva: result.condicion_iva_nombre,
        // proveedor_credito: result.credito,
      });
      setProveedorId(result.id);
      // handleSetCredito();
    } else {
      initProveedorValues();
    }

    handleRemoveAllAsociados();
    handleRemoveAllEjecuciones();
  };

  const handleInfo = (message) => {
    info({
      title: message,
      onOk() {},
    });
  };

  const handleSaveError = (message) => {
    error({
      title: message,
      onOk() {},
    });
  };

  const handleSaveSuccess = (message) => {
    success({
      title: message,
      onOk() {
        history.push(`/pagos`);
      },
    });
  };

  // ASOCIADO /////////////////////////////////////////////////////////////

  const handleAsociadosCancel = () => {
    setShowAddAsociados(false);
  };

  const handleAsociadosOk = (data) => {
    let asociados = form.getFieldValue('asociados')
      ? form.getFieldValue('asociados')
      : [];

    asociados = [
      ...asociados,
      ...data.map((item) => {
        if (!asociados.some((value) => value.id === item.id)) {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
          };
        } else {
          return null;
        }
      }),
    ];

    setShowAddAsociados(false);

    setAsociados(asociados.filter((item) => item !== null));

    calcularPagar();
  };

  const calcularPagar = () => {
    if (!stateData.object.data.id) {
      let totalPagar = 0;

      if (form.getFieldValue('asociados')) {
        form.getFieldValue('asociados').forEach((item) => {
          totalPagar = totalPagar + parseFloat(item.saldo);
        });
      }

      form.setFieldsValue({
        pagar: totalPagar.toFixed(2),
      });
      calcularDiferencia();
    }

    if (stateData.object.data.id) {
      let totalPagar = 0;

      if (form.getFieldValue('asociados')) {
        form.getFieldValue('asociados').forEach((item) => {
          totalPagar = totalPagar + parseFloat(item.pago);
        });
      }

      form.setFieldsValue({
        pagar: totalPagar.toFixed(2),
      });
      calcularDiferencia();
    }
  };

  const handleAsociadosSearch = () => {
    if (proveedorId) {
      setShowAddAsociados(true);
    } else {
      handleInfo('Debe seleccionar un Proveedor para asociar facturas');
    }
  };

  const handleRemoveAllAsociados = () => {
    setAsociados(null);
  };

  const handleDeleteAsociado = (data) => {
    setAsociados((prev) => {
      return [...prev.filter((item) => item.key !== data.key)];
    });

    const retencionesAsociados = form
      .getFieldValue('retenciones')
      .filter((item) => item.factura_id !== data.id);

    form.setFieldsValue({
      retenciones: retencionesAsociados,
    });

    setRetenciones(retencionesAsociados);
  };

  useEffect(() => {
    form.setFieldsValue({
      asociados: asociados,
    });
    calcularPagar();
    // eslint-disable-next-line
  }, [asociados]);

  const handleConfirmDeleteAsociado = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDeleteAsociado(item);
      },
      onCancel() {},
    });
  };

  // EJECUCIONES /////////////////////////////////////////////////////////////

  const handleEjecucionesCancel = () => {
    setShowAddEjecuciones(false);
  };

  const handleEjecucionesOk = (data) => {
    let ejecuciones = form.getFieldValue('ejecuciones')
      ? form.getFieldValue('ejecuciones')
      : [];

    ejecuciones = [
      ...ejecuciones,
      ...data.map((item) => {
        if (!ejecuciones.some((value) => value.id === item.id)) {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
          };
        } else {
          return null;
        }
      }),
    ];

    setShowAddEjecuciones(false);

    setEjecuciones(ejecuciones.filter((item) => item !== null));

    calcularCredito();
  };

  const calcularCredito = () => {
    if (!stateData.object.data.id) {
      let totalEjecutar = 0;

      if (form.getFieldValue('ejecuciones')) {
        form.getFieldValue('ejecuciones').forEach((item) => {
          totalEjecutar = totalEjecutar + parseFloat(item.saldo);
        });
      }

      form.setFieldsValue({
        credito: totalEjecutar.toFixed(2),
      });

      calcularSaldo();
    }
  };

  const handleEjecucionesSearch = () => {
    if (proveedorId) {
      setShowAddEjecuciones(true);
    } else {
      handleInfo(
        'Debe seleccionar un Proveedor para asociar comprobantes ha ejecutar'
      );
    }
  };

  const handleRemoveAllEjecuciones = () => {
    setEjecuciones(null);
  };

  const handleDeleteEjecucion = (data) => {
    setEjecuciones((prev) => {
      return [...prev.filter((item) => item.key !== data.key)];
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ejecuciones: ejecuciones,
    });
    calcularCredito();
    // eslint-disable-next-line
  }, [ejecuciones]);

  const handleConfirmDeleteEjecucion = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDeleteEjecucion(item);
      },
      onCancel() {},
    });
  };

  //////////////////////////////////////////////////////////////////////////////////

  const calcularSaldo = () => {
    const totalSaldo =
      parseFloat(form.getFieldValue('total')) +
      parseFloat(form.getFieldValue('credito'));
    form.setFieldsValue({
      saldo: totalSaldo.toFixed(2),
    });

    calcularDiferencia();
  };

  const calcularDiferencia = () => {
    const totalDiferencia =
      parseFloat(form.getFieldValue('saldo')) -
      parseFloat(form.getFieldValue('pagar'));
    form.setFieldsValue({
      diferencia: totalDiferencia.toFixed(2),
    });
  };

  const calcularTotal = () => {
    let totalTransferencias = 0;
    if (form.getFieldValue('transferencias')) {
      form.getFieldValue('transferencias').forEach((item) => {
        totalTransferencias = totalTransferencias + parseFloat(item.total);
      });
    }

    let totalCheques = 0;
    if (form.getFieldValue('cheques')) {
      form.getFieldValue('cheques').forEach((item) => {
        totalCheques = totalCheques + parseFloat(item.importe);
      });
    }
    let totalRetenciones = 0;
    if (form.getFieldValue('retenciones')) {
      form.getFieldValue('retenciones').forEach((item) => {
        totalRetenciones = totalRetenciones + parseFloat(item.total);
      });
    }
    let totalTarjetas = 0;
    if (form.getFieldValue('tarjetas')) {
      form.getFieldValue('tarjetas').forEach((item) => {
        totalTarjetas = totalTarjetas + parseFloat(item.total);
      });
    }

    let totalEfectivo = form.getFieldValue('efectivo')
      ? parseFloat(form.getFieldValue('efectivo'))
      : 0;

    const total =
      totalTransferencias +
      totalCheques +
      totalRetenciones +
      totalTarjetas +
      totalEfectivo;

    form.setFieldsValue({
      total: total,
    });

    calcularSaldo();
  };

  const handleSetTransferencias = (data) => {
    form.setFieldsValue({
      transferencias: data,
    });
    calcularTotal();
  };

  const handleSetCheques = (data) => {
    form.setFieldsValue({
      cheques: data,
    });
    calcularTotal();
  };

  const handleSetRetenciones = (data) => {
    form.setFieldsValue({
      retenciones: data,
    });
    setRetenciones(data);
    calcularTotal();
  };

  const handleSetTarjetas = (data) => {
    form.setFieldsValue({
      tarjetas: data,
    });
    calcularTotal();
  };
  const handleChangeEfectivo = (e) => {
    calcularTotal();
  };

  const onChangeTipo = (value) => {
    setTipoId(value);

    if (value === 2) {
      form.setFieldsValue({
        asociados: undefined,
        pagar: 0,
        retenciones: undefined,
        credito: 0,
      });
      setAsociados(null);
      setRetenciones(null);
      setEjecuciones([]);
      calcularSaldo();
      // setUsarCredito(false);
    }
  };

  return (
    <Spin spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}>
      <Layout style={{ height: '100%' }}>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={stateData.object.data}
          size="small"
        >
          <Header>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>

                <Form.Item
                  name="transferencias"
                  label="transferencias"
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="cheques"
                  label="cheques"
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="tarjetas"
                  label="tarjetas"
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="retenciones"
                  label="retenciones"
                  style={{ display: 'none' }}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Card
              size="small"
              style={{ marginBottom: '10px' }}
              extra={
                stateData.object.data.numero && (
                  <span style={{ fontSize: '18px' }}>
                    <b> Pago N°: {stateData.object.data.numero}</b>
                  </span>
                )
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<CalendarOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Datos Generales</span>
                </TitleCard>
              }
            >
              <Row gutter={[8, 0]} align="middle">
                <Col xs={24} sm={24} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="fecha"
                    label="Fecha emisión"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    {!stateData.object.data.id ? (
                      <DatePicker
                        format={formatDisplay}
                        style={{ width: '100%' }}
                        initialValue={moment(new Date())}
                      />
                    ) : (
                      <Input readOnly />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="tipo_id"
                    label="Tipo"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      onChange={onChangeTipo}
                      open={stateData.object.data.id ? false : undefined}
                    >
                      {cobroTipo.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                  <Form.Item
                    name="ubicacion_id"
                    label="Ubicación"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select>
                      {ubicacion.list.data.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.nombre}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card
              extra={
                !stateData.object.data.id ? (
                  proveedorId ? (
                    <FormOutlined
                      style={{ fontSize: '18px' }}
                      onClick={() => setShowEditProveedor(true)}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{ fontSize: '18px' }}
                      onClick={() => setShowEditProveedor(true)}
                    />
                  )
                ) : (
                  false
                )
              }
              title={
                <TitleCard>
                  <Avatar
                    icon={<UserOutlined />}
                    size="small"
                    style={{ backgroundColor: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}> Proveedor</span>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
            >
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                  {!stateData.object.data.id ? (
                    <Form.Item
                      name="proveedor_id"
                      label="Proveedor"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                    >
                      {proveedor.list.status === callStatus.LIST_SUCCESS ? (
                        <Select
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          onSearch={onSearchProveedor}
                          onChange={onChangeProveedor}
                          onPressEnter={(e) => handleOnSearchProveedorEnter(e)}
                          onBlur={(e) => handleOnSearchProveedorBlur(e)}
                          notFoundContent={null}
                          allowClear
                          ref={proveedorRef}
                        >
                          {resultSearchProveedor.map((item, index) => (
                            <Option key={index} value={item.id}>
                              {item.cuit && `${item.cuit} - `}
                              {item.nombre}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Spin spinning={true} size="small">
                          <Input readOnly />
                        </Spin>
                      )}
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        name="proveedor_id"
                        label="Proveedor"
                        style={{ display: 'none' }}
                      >
                        <Input readOnly />
                      </Form.Item>
                      <Form.Item
                        name="proveedor_nombre_completo"
                        label="Proveedor"
                      >
                        <Input readOnly />
                      </Form.Item>
                    </>
                  )}
                </Col>

                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                  <Form.Item
                    name="proveedor_nombre"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                  <Form.Item
                    name="proveedor_condicion_iva"
                    label="Condición IVA"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Header>
          <Content>
            {tipoId === 1 && (
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Card
                    extra={
                      !stateData.object.data.id && (
                        <PlusCircleOutlined
                          style={{ fontSize: '18px' }}
                          onClick={() => handleAsociadosSearch()}
                        />
                      )
                    }
                    title={
                      <TitleCard>
                        <Avatar
                          icon={<FileAddOutlined />}
                          size="small"
                          style={{ backgroundColor: '#4f43ba' }}
                        />
                        <span style={{ marginLeft: '3px' }}>
                          Comprobantes para pagar
                        </span>
                      </TitleCard>
                    }
                    style={{ marginBottom: '10px' }}
                    size="small"
                    bodyStyle={
                      !asociados || asociados.length === 0 ? { padding: 0 } : {}
                    }
                  >
                    <Row>
                      <Form.List
                        name="asociados"
                        rules={[
                          {
                            validator: async (_, asociados) => {
                              if (
                                tipoId === 1 &&
                                (!asociados || asociados.length < 1)
                              ) {
                                return Promise.reject(
                                  new Error(
                                    'Seleccione al menos un comprobante'
                                  )
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {errors.length > 0 && (
                              <Row
                                gutter={[0, 0]}
                                style={{ width: '100%' }}
                                align="top"
                                justify="center"
                              >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Form.List>
                      {asociados && asociados.length > 0 && (
                        <MetaTable
                          dataSource={asociados}
                          dataColumns={columnsAsociado}
                          bordered={false}
                          selection={false}
                          cssStyle={true}
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Card
                    extra={
                      !stateData.object.data.id && (
                        <PlusCircleOutlined
                          style={{ fontSize: '18px' }}
                          onClick={() => handleEjecucionesSearch()}
                        />
                      )
                    }
                    title={
                      <TitleCard>
                        <Avatar
                          icon={<FileAddOutlined />}
                          size="small"
                          style={{ backgroundColor: '#4f43ba' }}
                        />
                        <span style={{ marginLeft: '3px' }}>
                          Créditos para ejecutar
                        </span>
                      </TitleCard>
                    }
                    style={{ marginBottom: '10px' }}
                    size="small"
                    bodyStyle={
                      !ejecuciones || ejecuciones.length === 0
                        ? { padding: 0 }
                        : {}
                    }
                  >
                    <Row>
                      <Form.List name="ejecuciones">
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {errors.length > 0 && (
                              <Row
                                gutter={[0, 0]}
                                style={{ width: '100%' }}
                                align="top"
                                justify="center"
                              >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item>
                                    <Form.ErrorList errors={errors} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            )}
                          </>
                        )}
                      </Form.List>
                      {ejecuciones && ejecuciones.length > 0 && (
                        <MetaTable
                          dataSource={ejecuciones}
                          dataColumns={columnsEjecucion}
                          bordered={false}
                          selection={false}
                          cssStyle={true}
                        />
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
            <Divider orientation="left">Forma de Pago</Divider>
            <Card
              title={
                <TitleCard>
                  <DollarOutlined
                    style={{ fontSize: '22px', color: '#4f43ba' }}
                  />
                  <span style={{ marginLeft: '3px' }}>Efectivo</span>{' '}
                  <Form.Item
                    name="efectivo"
                    style={{ marginLeft: '10px', marginBottom: 0 }}
                  >
                    <Input
                      prefix={'$'}
                      type="number"
                      onBlur={(e) => handleChangeEfectivo(e)}
                      readOnly={!stateData.object.data.id ? false : true}
                    />
                  </Form.Item>
                </TitleCard>
              }
              style={{ marginBottom: '10px' }}
              size="small"
              bodyStyle={{ padding: 0 }}
            ></Card>

            <TransferenciaList
              id={stateData.object.data.id}
              dataSource={
                form.getFieldValue('transferencias') &&
                form.getFieldValue('transferencias').length
                  ? form.getFieldValue('transferencias')
                  : null
              }
              onChange={handleSetTransferencias}
            />
            <ChequeList
              id={stateData.object.data.id}
              dataSource={
                form.getFieldValue('cheques') &&
                form.getFieldValue('cheques').length
                  ? form.getFieldValue('cheques')
                  : null
              }
              onChange={handleSetCheques}
              isCobro={false}
            />
            <TarjetaList
              id={stateData.object.data.id}
              dataSource={
                form.getFieldValue('tarjetas') &&
                form.getFieldValue('tarjetas').length
                  ? form.getFieldValue('tarjetas')
                  : null
              }
              onChange={handleSetTarjetas}
            />
            {tipoId === 1 && (
              <RetencionList
                id={stateData.object.data.id}
                dataSource={
                  retenciones && retenciones.length ? retenciones : null
                }
                onChange={handleSetRetenciones}
                facturas={asociados && asociados.length ? asociados : null}
              />
            )}
          </Content>
          <Footer>
            <Card
              style={{ marginBottom: '10px' }}
              bodyStyle={{ padding: '10px' }}
            >
              <Row justify="end">
                {tipoId === 1 && (
                  <Col>
                    <Form.Item
                      name="pagar"
                      label={
                        !stateData.object.data.id
                          ? 'Total para pagar'
                          : 'Total pagado'
                      }
                    >
                      <Statistic
                        prefix="$"
                        precision={2}
                        valueStyle={{ color: '#cf1322' }}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col style={{ marginLeft: '20px' }}>
                  <Form.Item name="total" label="Total entregado">
                    <Statistic
                      prefix="$"
                      precision={2}
                      valueStyle={tipoId === 1 ? {} : { color: '#3f8600' }}
                    />
                  </Form.Item>
                </Col>
                {tipoId === 1 && (
                  <Col style={{ marginLeft: '20px' }}>
                    <Form.Item
                      name="credito"
                      label={
                        !stateData.object.data.id
                          ? 'Crédito a ejecutar'
                          : 'Crédito ejecutado'
                      }
                    >
                      <Statistic prefix="$" precision={2} />
                    </Form.Item>
                  </Col>
                )}
                {tipoId === 1 && (
                  <Col style={{ marginLeft: '20px' }}>
                    <Form.Item name="saldo" label="Saldo Total">
                      <Statistic
                        prefix="$"
                        precision={2}
                        valueStyle={{ color: '#3f8600' }}
                      />
                    </Form.Item>
                  </Col>
                )}

                {tipoId === 1 && (
                  <Col style={{ marginLeft: '20px' }}>
                    <Form.Item name="diferencia" label="Diferencia">
                      <Statistic
                        prefix="$"
                        precision={2}
                        valueStyle={{ color: '#096dd9' }}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Card>

            <Row gutter={[8, 0]} justify="end" style={{ marginBottom: '10px' }}>
              <Col>
                <Button
                  type="default"
                  icon={<CloseCircleOutlined />}
                  size="small"
                  onClick={(e) => handleOnCancel()}
                >
                  Cancelar
                </Button>
              </Col>
              {!stateData.object.data.id && (
                <Col>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    size="small"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          if (tipoId === 1) {
                            handlePagarComprobante(values);
                          } else {
                            handleCrearAnticipo(values);
                          }
                        })
                        .catch((info) => {
                          console.log('Validate Failed:', info);
                        });
                    }}
                  >
                    Generar Comprobante
                  </Button>
                </Col>
              )}
            </Row>
          </Footer>
        </Form>

        {showEditProveedor ? (
          <ProveedorEditor
            id={proveedorId}
            onCancel={handleHideEditorProveedor}
            visible={true}
          />
        ) : (
          false
        )}

        {showAddAsociados ? (
          <FacturasAsociadasPopup
            proveedor={proveedorId}
            onCancel={handleAsociadosCancel}
            onOk={handleAsociadosOk}
            itemSelected={form.getFieldValue('asociados')}
          />
        ) : (
          false
        )}

        {showAddEjecuciones ? (
          <EjecucionesAsociadasPopup
            proveedor={proveedorId}
            onCancel={handleEjecucionesCancel}
            onOk={handleEjecucionesOk}
            itemSelected={form.getFieldValue('ejecuciones')}
          />
        ) : (
          false
        )}
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  const { pago, proveedor, configuracion, cobroTipo, ubicacion } = state;
  const stateData = pago;
  return {
    stateData,
    proveedor,
    configuracion,
    cobroTipo,
    ubicacion,
  };
};
export default connect(mapStateToProps)(PagoEditor);
